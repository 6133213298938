.logo {
  height:50px;
  width:50px;
  margin-right: 0.8rem;
}

header .media {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

header .media-heading {
  margin-bottom: 0.1rem;
}

header .media-body {
  font-size: 0.7rem;
}

.summ {
  margin: 0.67rem -15px 0.67rem;
  text-align: center;
}

.summ .card-body {
  padding: 0.66rem;
  border-radius: 0.66rem;
  background-color: #f2f2f2;
  border: 0;
}

.summ .card-title {
  margin-bottom: 0;
}

.summ .card-text {
  font-size: 1.5rem;
  font-weight: 500;
}

.items img {
  border-radius: 0.66rem;
  margin-right: 0.75rem;
  /* width: 90px; */
}

.items .media {
  margin: 0 -15px;
  padding: 0.67rem 15px;
}

.items .media:active {
  background-color: #f2f2f2;
}

.items .media-body {
  font-size: 0.9rem;
  color: #666;
}

.items .media-heading {
  margin-bottom: 0;
  color: #191919;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25rem;
  white-space: nowrap;
}

.items .item-price {
  /* padding: 0 0.66rem; */
  line-height: 1.3;
  color: #ff8303;
  /* background-color: #ff8303; */
  /* border-radius: 0.27rem; */
  font-size: 1.13rem;
  font-weight: 500;
}

.items .item-note {
  /* width: 157px; */
  line-height: 2;
  font-size: 0.8rem;
  /* font-weight: normal;
  font-style: normal;
  font-stretch: normal; */
  /* letter-spacing: normal; */
  color: #999999;
}

.items .item-desc {
  max-height: 1.8rem;
  line-height: 1.14;
  font-size: 0.8rem;
  overflow: hidden;
}

.list-header {
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 0.3rem;
}

.list-header {
  height: 1.1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  color: #666666;
}

.container .since {
  line-height: 1;
  font-size: 0.8rem;
  color: #999999;
  /* margin-bottom: 0.2rem; */
}
