.logo {
  height:50px;
  width:50px;
  margin-right: 0.8rem;
}

header .media {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

header .media-heading {
  margin-bottom: 0.1rem;
}

header .media-body {
  font-size: 0.7rem;
}

.summ {
  margin: 0.67rem -15px 0.67rem;
  text-align: center;
}

.summ .card-body {
  padding: 0.66rem;
  border-radius: 0.66rem;
  background-color: #f2f2f2;
  border: 0;
}

.summ .card-title {
  margin-bottom: 0;
}

.summ .card-text {
  font-size: 1.5rem;
  font-weight: 500;
}

.items img {
  border-radius: 0.66rem;
  margin-right: 0.75rem;
  /* width: 90px; */
}

.items .media {
  margin: 0 -15px;
  padding: 0.67rem 15px;
}

.items .media:active {
  background-color: #f2f2f2;
}

.items .media-body {
  font-size: 0.9rem;
  color: #666;
}

.items .media-heading {
  margin-bottom: 0;
  color: #191919;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25rem;
  white-space: nowrap;
}

.items .item-price {
  /* padding: 0 0.66rem; */
  line-height: 1.3;
  color: #ff8303;
  /* background-color: #ff8303; */
  /* border-radius: 0.27rem; */
  font-size: 1.13rem;
  font-weight: 500;
}

.items .item-note {
  /* width: 157px; */
  line-height: 2;
  font-size: 0.8rem;
  /* font-weight: normal;
  font-style: normal;
  font-stretch: normal; */
  /* letter-spacing: normal; */
  color: #999999;
}

.items .item-desc {
  max-height: 1.8rem;
  line-height: 1.14;
  font-size: 0.8rem;
  overflow: hidden;
}

.list-header {
  text-align: center;
  margin-top: 1.2rem;
  margin-bottom: 0.3rem;
}

.list-header {
  height: 1.1rem;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 1;
  letter-spacing: normal;
  color: #666666;
}

.container .since {
  line-height: 1;
  font-size: 0.8rem;
  color: #999999;
  /* margin-bottom: 0.2rem; */
}

.product.with-comments {
    padding-bottom: 5rem;
}
.product img {
    margin-bottom: 0.5rem;
    box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
}

.product .card {
    border: 0;
}

.product .card-title {
    margin-bottom: 0;
    font-size: 0.9rem;
    color: #666666;
}

.product .card-body {
    padding: 0.7rem 1.25rem;
}

.show-comments {
    position: fixed;
    bottom: 1rem;
    left: 0;
    right: 0;
    text-align: center;
}

.show-comments a {
    display: inline-block;
    line-height: 3rem;
    padding: 0 3rem;
    border-radius: 1.5rem;
    color: #ffffff;
    font-size: 1.1rem;
    opacity: 1;
    background-color: #ff8303;
}
.comments {
    padding: 1.67rem;
}
.comments .card {
    position: relative;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #f2f2f2;
}

.comments .card:last-child {
    border-bottom: 0;
}

.comments .card:before, .comments .card:after {
    position: absolute;
    content: '';
    top: 50%;
    width: 0;
    height: 0;
    border: solid transparent;
    pointer-events: none;
}

.comments .card:after {
    border-color: rgba(255, 255, 255, 0);
	border-left-color: #fff;
	border-width: 5px;
    margin-top: -5px;
    right: -2px;
}

.comments .card:before {
    border-color: rgba(0, 0, 0, 0);
	border-left-color: #666666;
	border-width: 8px;
    margin-top: -8px;
    right: -8px;
}

.comments .card-body {
    padding: 1.25rem 0;
}

.comments .card-title {
    margin-bottom: 0;
    font-size: 0.9rem;
    color: #666666;
}

.comments .card-text {
    font-size: 1.2rem;
    color: #191919;
}

.comment {
    margin: 1rem 0;
}
.comment .card {
    border: 0;
}

.comment .card-title {
    margin-bottom: 0;
    font-size: 0.9rem;
    color: #666666;
}

.comment .card-body {
    padding: 0.7rem 1.25rem;
}
/*
 * @NOTE: Prepend a `~` to css file paths that are in your node_modules
 *        See https://github.com/webpack-contrib/sass-loader#imports
 */

body {
  position: relative;
  height: 100vh;
  /* background-color: #232c39; */
  /* background-image: linear-gradient(45deg, rgba(0, 216, 255, 0.5) 10%, rgba(0, 1, 127, 0.7)); */
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  margin: 0;
  font-size: 2.25rem;
  font-weight: bold;
  letter-spacing: -0.025em;
}

/* p {
  font-size: 24px;
} */

li {
  list-style: none;
}

a {
  opacity: 0.75;
  text-decoration: none;
}

a:hover {
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}


.lds-ring {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  position: absolute;
  left: 50%;
  margin-left: -1.76rem;
  top: 50%;
  margin-top: -1.76rem;
  width: 3.33rem;
  height: 3.33rem;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 0.5rem;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1.53rem;
  height: 1.53rem;
  border: 0.13rem solid #ff8303;
  border-radius: 50%;
  -webkit-animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
          animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #ff8303 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  -webkit-animation-delay: -0.45s;
          animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  -webkit-animation-delay: -0.15s;
          animation-delay: -0.15s;
}
@-webkit-keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
@keyframes lds-ring {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
